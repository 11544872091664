<template>
    <div class="page">
      <template v-if="!showSuccess">
        <h1 class="page-title">{{$t('reseller.pageTitle')}}</h1>
        <p class="page-desc"></p>
        <div class="container">
          <div class="pricing-info-form">
            <el-form ref="form" label-position="top" label-width="320px" :model="form" :rules="rules">
              <el-row :gutter="60">
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.companyName')" prop="companyName">
                    <el-input v-model="form.companyName" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.webSite')" prop="webSite">
                    <el-input v-model="form.webSite" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
                <!-- <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.region')" prop="region">
                    <el-select placeholder="" v-model="form.region" clearable filterable @change="changeRegion" style="width: 100%;">
                        <el-option v-for="(item, index) in areaArr" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.coopCountry')" prop="coopCountry">
                    <el-select placeholder="" v-model="form.coopCountry" clearable filterable style="width: 100%;" @change="filterCompany">
                        <el-option v-for="(item, index) in countryArr" :key="index" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.distributor')" prop="Vendor">
                    <el-select placeholder="" v-model="form.vendor" clearable filterable style="width: 100%;">
                      <el-option v-for="(item, index) in companyArr" :key="index" :label="item.name" :value="item.name"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.preSales')" prop="preSales">
                    <el-input v-model="form.preSales" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.postSales')" prop="postSales">
                    <el-input v-model="form.postSales" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
                
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.marketing')" prop="marketing">
                    <el-input v-model="form.marketing" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.email')" prop="email">
                    <el-input v-model="form.email" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>

                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.companyEmail')" prop="email">
                    <el-input v-model="form.companyEmail"  v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>
    
                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.phone')" prop="phone">
                    <el-input v-model="form.phone" v-bind="inputAttr" placeholder="Format: Area Code-Number eg. 0066-123456"/>
                  </el-form-item>
                </el-col>

                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.address')" prop="address">
                    <el-input v-model="form.address" v-bind="inputAttr"/>
                  </el-form-item>
                </el-col>

                <el-col :md="12" :xs="24">
                  <el-form-item :label="$t('reseller.partnerType')" prop="partnerType">
                    <el-select placeholder="" v-model="form.partnerType" clearable filterable style="width: 100%;">
                        <el-option label="Tier 2 (Reseller)" value="Tier 2 (Reseller)"></el-option>
                        <el-option label="Tier 2 (System Integrator)" value="Tier 2 (System Integrator)"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :md="24" :xs="24">
                  <el-form-item :label="$t('reseller.uploadLog')" prop="logo">
                    <el-row :gutter="60">
                      <el-col :md="12" :xs="24">
                        <el-input v-bind:value="form.logo" disabled/>
                        <!-- <div class="el-input__inner" style="background-color: #F5F7FA; color: #C0C4CC; cursor: not-allowed;">{{form.logo}}</div> -->
                      </el-col>
                      <el-col :md="12" :xs="24">
                        <el-upload class="upload-demo" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'cooperation'}" :before-upload="beforeFileUpload" :on-success="handleFileSuccess1">
                          <el-button type="primary">{{$t('reseller.upload')}}</el-button>
                        </el-upload>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>

                <el-col :md="24" :xs="24">
                  <el-form-item :label="$t('reseller.businessLicense')" prop="businessLicense">
                    <el-row :gutter="60">
                      <el-col :md="12" :xs="24">
                        <el-input v-bind:value="form.businessLicense" disabled/>
                      </el-col>
                      <el-col :md="12" :xs="24">
                        <el-upload class="upload-demo" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'cooperation'}" :before-upload="beforeFileUpload2" :on-success="handleFileSuccess2">
                          <el-button type="primary">{{$t('reseller.upload')}}</el-button>
                        </el-upload>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>

                <el-col :md="24" :xs="24">
                  <el-form-item :label="$i18n.t('reseller.uploadTips')" prop="partnerAgreement">
                    <el-row :gutter="60">
                      <el-col :md="12" :xs="24">
                        <el-input v-bind:value="form.partnerAgreement" disabled/>
                      </el-col>
                      <el-col :md="12" :xs="24">
                        <el-row :gutter="60">
                          <el-col :md="6" :xs="24">
                            <el-upload class="upload-demo" :show-file-list="false" :headers="headers" action="/api/file/file/anyone/upload" :data="{...fileBaseData,bizType:'cooperation'}" :before-upload="beforeFileUpload3" :on-success="handleFileSuccess3">
                              <el-button type="primary">{{$t('reseller.upload')}}</el-button>
                            </el-upload>
                          </el-col>
                          <el-col :md="18" :xs="24">
                            <el-button type="primary" @click="btnDownload">{{$t('reseller.downlaod')}}</el-button>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-form-item prop="subscribe" label-width="0" hide-required-asterisk>
                    <div class="agree-field">
                      <div class="agree-field-text">{{$t('reseller.agreeFieldText')}}</div>
                      <el-radio-group v-model="form.subscribe">
                        <el-radio :label="true">{{ $i18n.t('contactUs.pricingInfo.Yes') }}</el-radio>
                        <el-radio :label="false">{{ $i18n.t('contactUs.pricingInfo.No') }}</el-radio>
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-col>
                
                <el-col>
                  <el-form-item prop="agree" label-width="0" hide-required-asterisk>
                    <div class="check-field">
                      <el-checkbox v-model="form.agree" :false-label="null"></el-checkbox>
                      <div class="check-field-wrap">
                        <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.AgreeText') }}</span>
                        <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.PrivacyPolicy') }}</span>
                        <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.And') }}</span>
                        <span class="check-field-link">{{ $i18n.t('contactUs.pricingInfo.TermsOfUse') }}</span>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>

                <el-col>
                  <el-form-item prop="agree" label-width="0" hide-required-asterisk>
                    <div class="check-field">
                      <div class="check-field-wrap">
                        <span class="check-field-text">{{ $i18n.t('contactUs.pricingInfo.publictiy') }}</span>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>

              </el-row>
    
              <div>
                <div class="submit-btn" @click="submit" v-loading.fullscreen.lock="fullscreenLoading">
                  {{ $i18n.t('contactUs.pricingInfo.submit') }}
                </div>
              </div>
    
            </el-form>
          </div>
        </div>
      </template>
      <template v-if="showSuccess">
          <div class="success-panel">
              <img class="success-panel-icon" src="../../assets/image/login/icon_success.png" alt="success">
              <div class="success-panel-title">{{$t('reseller.successPanelTitle')}}</div>
              <p class="success-panel-text">{{$t('reseller.successPanelText')}}</p>
          </div>
      </template>
    </div>
  </template>
  
  <script>
    import {save} from '@/api/resellerApi';
    import {cloneDeep} from 'lodash';
    // import distributorMaps from '@/utils/distributorMaps.json';
    import countrys from '@/utils/country.json';
    import {download} from '@/api/documentation';
    import {downloadFile,initDicts} from '@/utils/commons';

    export default {
      name: 'ResellerIndex',
      data() {
        let validatorEmail = (rule, value, callback) => {
          if (value.length > 254) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.length-error')));
          } else if (value.indexOf('@') === -1) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.at-error')));
          } else if (!/^(\w|[!#$%&’*+-/=?^`{}|~.])+@[^@]*$/.test(value)) {
            // invalidLetter = value.replace(/@[^@]+$/, '').replace(/\w|[!#$%&’*+-/=?^`{}|~.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-letter-forbidden')));
          } else if (/[.]{2}/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.double-dot-error')));
          } else if (!/^.{1,63}@[^@]*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.account-length-error')));
          } else if (!(/(^[^.].*@[^@]*$)/.test(value) && /^.*[^.]@[^@]*$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.prevDot-error')));
          } else if (!/^[^@]+@([0-9]|[A-Z]|[a-z]|[\-.])+$/.test(value)) {
            // invalidLetter = value.replace(/^[^@]+@/, '').replace(/[A-Za-z0–9\-.]/g, '');
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLetter-forbidden')));
          } else if (!(/^[^@]+@[^-].*$/.test(value) && /^[^@]+@.*[^-]$/.test(value))) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.nextLine-error')));
          } else if (!/^[^@]+@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
            callback(new Error(this.$i18n.t('contactUs.pricingInfo.emailValidator.domain-error')));
          } else {
            callback();
          }
  
        };
  
        let fieldRequired = (trigger = 'blur') => {
          return {
            required: true,
            message: this.$i18n.t('contactUs.pricingInfo.fieldRequired'),
            trigger,
          };
        };
  
        return {
          fullscreenLoading: false,
  
          form: {
            companyName: '',
            webSite: '',
            region: '',
            coopCountry: '',
            vendor: '',
            preSales: '',
            postSales: '',
            marketing: '',
            email: '',
            companyEmail: '',
            phone: '',
            address: '',
            partnerType: '',
            logo: '',
            businessLicense: '',
            partnerAgreement: '',
            'subscribe': null,
            'agree': null,
          },
  
          rules: {
            email: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            companyEmail: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            preSales: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            postSales: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            marketing: [
              fieldRequired('blur'),
              {required: true, validator: validatorEmail, trigger: 'blur'},
            ],
            logo: fieldRequired('blur'),
            businessLicense: fieldRequired('blur'),
            partnerAgreement: fieldRequired('blur'),
            // webSite: fieldRequired('blur'),
            phone: [
              fieldRequired('blur'),
              {
                  required: true, validator: (rele, value, callback) => {
                    if (value) {
                      if (/^((00)|\+)\d+$/.test(value)) {
                        callback();
                      } else {
                        callback(new Error(this.$i18n.t('contactUs.pricingInfo.phoneFormatError')));
                      }
                    } else {
                      callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
                    }
                  }, trigger: ['blur', 'change']
              }
            ],
            // region: fieldRequired('change'),
            coopCountry: fieldRequired('change'),
            vendor: fieldRequired('change'),
            companyName: fieldRequired('blur'),
            address: fieldRequired('blur'),
            partnerType: fieldRequired('blur'),
            industry: fieldRequired('blur'),
            subscribe: fieldRequired('change'),
            agree: [
              fieldRequired('change'),
              {
                required: true, validator: (rule, value, callback) => {
                  if (!value) {
                    callback(new Error(this.$i18n.t('contactUs.pricingInfo.fieldRequired')));
                  } else {
                    callback();
                  }
                }, trigger: 'change',
              },
            ],
            partner: fieldRequired('blur'),
            scope: fieldRequired('blur')
          },
  
          inputAttr: {
            placeholder: this.$i18n.t('contactUs.pricingInfo.placeholder'),
            maxlength: '100',
            clearable: true,
          },
  
          prevPagePath: '/',

          areaArr: [],
          countryArr: [],
          showSuccess: false,
          fileBaseData: {
            bucket: "hwtresources",
            storageType: "HUAWEI_OSS",
            hash: true
          },
          headers: {},
          dicts: {
            companyName: []
          },
          companyArr: [],
          companyNames: []
        };
      },
      created() {
        // this.areaArr = distributorMaps.area.map(item => {
        //     return {label: item, value: item}
        // })
        this.countryArr = countrys.country.map(item => {
            return {label: item, value: item}
        })
        this.headers = {
          // token: 'Bearer ' + db.get('HOLOWITS_TOKEN'),
          tenant: 'aG9sb3dpdHM=',
          Authorization: `Basic ${Base64.encode(`${process.env.VUE_APP_CLIENT_ID}:${process.env.VUE_APP_CLIENT_SECRET}`)}`
        }
      },
      async mounted() {
        await initDicts(['companyName'], this.dicts);
        for (let item of this.dicts.companyName) {
            this.companyNames.push(JSON.parse(item.code))
        }
      },
      beforeRouteEnter(to, from, next) {
        next(vm => {
          vm.prevPagePath = from.path;
        });
      },
      methods: {
        filterCompany(item) {
            this.form.vendor = ''
            let companyArr = this.companyNames.filter(i => i.country === item && i.type === "distributor");
            this.companyArr = companyArr;
        },
        async btnDownload() {
          download(['1614835284183089152'],{"isHolowits": true}).then(response => {
            downloadFile(response);
          })
        },
        beforeFileUpload(file) {
          const FileExt = file.name.replace(/.+\./, '').toLowerCase()
          const isLt2M = file.size / 1024 / 1024 < 30;
          const imageExt = ['jpg', 'png', 'webp', 'jpeg'];
          if (!imageExt.includes(FileExt)) {
            this.$message.error('Format error, please upload a jpg or png file')
            return false;
          }
          if (!isLt2M) {
            this.$message.error('The file size should not exceed 30M')
            return false;
          }
          
          // const _URL = window.URL || window.webkitURL
          // const img = new Image()
          // img.src = _URL.createObjectURL(file);
          // img.onload = () => {
          //   const {width, height} = img;
          //   if (width !== 170 || height !== 170) {
          //     this.$message.error('Please upload an image of 170*170 pixels')
          //     return false;
          //   }
          // }
          return true;
        },
        beforeFileUpload2(file) {
          const FileExt = file.name.replace(/.+\./, '').toLowerCase()
          const isLt2M = file.size / 1024 / 1024 < 50;
          const imageExt = ['jpg', 'png', 'webp', 'jpeg', 'pdf'];
          if (!imageExt.includes(FileExt)) {
            this.$message.error('Format error, please upload a jpg or png file')
            return false;
          }
          if (!isLt2M) {
            this.$message.error('The file size should not exceed 50M')
            return false;
          }
          return true;
        },
        beforeFileUpload3(file) {
          const FileExt = file.name.replace(/.+\./, '').toLowerCase()
          const imageExt = ['pdf'];
          if (!imageExt.includes(FileExt)) {
            this.$message.error('Format error, please upload a pdf file')
            return false;
          }
          return true;
        },
        handleFileSuccess1(res, file) {
          if (!res.isSuccess) {
            this.$message.error('System Error,Upload Fail');
            return;
          }
          this.form.logo = res.data.url
        },
        handleFileSuccess2(res, file) {
          if (!res.isSuccess) {
            this.$message.error('System Error,Upload Fail');
            return;
          }
          this.form.businessLicense = res.data.url
        },
        handleFileSuccess3(res, file) {
          if (!res.isSuccess) {
            this.$message.error('System Error,Upload Fail');
            return;
          }
          this.form.partnerAgreement = res.data.url
        },
        changeRegion(region) {
          this.form.coopCountry = ''
          this.countryArr = distributorMaps[region].map(item => {
            return {label: item, value: item}
          })
        },
        submit() {
          this.$refs.form.validate((valid) => {
            if (valid) {
              let bodyData = cloneDeep(this.form);
              save(bodyData).then(({data: {data}}) => {
                this.showSuccess = true
              })
            }
            // this.$router.push({path: this.prevPagePath});
          }); 
        },
      },
    };
  </script>
  
  <style scoped lang="less">
    /deep/.el-upload {
      .el-upload__input {
        display: none !important;
      }
    }
    .page {
      position: relative;
      padding-bottom: 20px;
      background-color: #F8F8F8;
  
      &:after {
        content: '';
        background-color: #F8F8F8;
        position: absolute;
        height: 100vh;
        left: 0;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }
    
    .success-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .success-panel-icon {
        width: 88px;
        margin-bottom: 10px;
      }

      .success-panel-title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }

      .success-panel-text{
        font-size: 18px;
        font-weight: 400;
        color: #666666;
        max-width: 500px;
        margin: 30px 0 50px;
      }

      .el-button{
        display: block;
        width: 307px;
        max-width: 100%;
        height: 66px;
        font-size: 18px;
        border-radius: 8px;
      }
    }

    .page-title {
      text-align: center;
      padding: 50px 0 26px;
      font-size: 42px;
      font-weight: 400;
      color: #000000;
    }
  
    .page-desc {
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      text-align: center;
      margin-bottom: 26px;
    }
  
  
    .pricing-info-form {
      background-color: #FFFFFF;
      padding: 40px 60px;
      transition: .3s;
  
      @media (max-width: 750px) {
        & {
          padding: 30px;
        }
  
        .submit-btn {
          height: 50px;
          max-width: 100%;
        }
      }
  
  
  
      /deep/ .el-form-item {
        //display: flex;
        //width: 100%;
        //margin-bottom: 30px;
  
        //.el-form-item__label {
        //  white-space: nowrap;
        //}
  
        .el-input, .el-select {
          width: 100%;
        }
  
        //.el-form-item__content {
        //  flex: 1;
        //  width: 100%;
        //}
  
        .el-textarea {
          &.has-value:after {
            display: none;
          }
  
          &:after {
            content: '*';
            color: #f56c6c;
            position: absolute;
            top: 0;
            left: 7px;
            line-height: 32px;
          }
        }
      }
  
      .agree-field {
        .agree-field-text {
          font-weight: 400;
          color: #666666;
          line-height: 1.6;
  
          &:before {
            content: '*';
            color: #f56c6c;
            margin-right: 4px;
          }
        }
      }
  
      .check-field {
        line-height: 1.6;
        display: flex;
  
        .check-field-wrap {
          flex: 1;
          width: 100%;
        }
  
        .el-checkbox {
          margin-right: 10px;
        }
  
        .check-field-text {
          color: #999999;
          font-weight: 400;
        }
  
        .check-field-link {
          color: #333333;
          font-weight: 400;
          padding: 0 5px;
          cursor: pointer;
  
          &:hover {
            text-decoration: underline;
          }
  
          &:active {
            opacity: .7;
          }
        }
      }
    }
  
    .submit-btn {
      max-width: 250px;
      height: 60px;
      background: #C7000B;
      border-radius: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      margin: 0 auto;
      cursor: pointer;
      user-select: none;
      transition: .1s ease-out;
      margin-top: 20px;
  
      &:hover {
        opacity: .8;
      }
  
      &:active {
        opacity: 1;
      }
    }
  </style>
  